<i18n>
{
  "de": {
    "lifespanTitle": "Lebensdauer",
    "nameTitle": "Komponente",
    "yearsUnit": "Jahre",
    "components": {
      "basement": "Keller / Kellerdecke",
      "heating": "Wärmeerzeuger",
      "heating_distribution": "Wärmeverteilung",
      "pv": "Photovoltaikanlage",
      "roof_flat": "Flachdach",
      "roof_gable": "Schrägdach",
      "walls": "Fassade",
      "windows": "Fenster"
    }
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    class="c-edit-lifespans"
    :allow-edit="getPortfolioPermission('EDIT_LIFESPANS')"
    @container="onFormEvent"
  >
    <DetailList has-header class="lifespans-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span>{{ $t('lifespanTitle') }}</span>
      </template>
      <li v-for="(lifespan, componentKey) in model" :key="componentKey">
        <div>
          {{ $t(`components.${componentKey}`) }}
        </div>
        <div>
          <NumericInput
            v-model="model[componentKey]"
            :edit="isEditing"
            optional
            :units="$t('yearsUnit')"
            @validation="onValidation($event, 'lifespan-' + componentKey)"
          />
        </div>
      </li>
    </DetailList>
  </EditContainer>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    DetailList,
    NumericInput,
    EditContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams',
    }),

    resetModel() {
      // Deep copy
      this.model = JSON.parse(JSON.stringify(this.portfolio.lifespans))
    },

    async saveHandler() {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          lifespans: this.model,
        },
      })
    },
  },
}
</script>
